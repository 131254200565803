import type { GetServerSideProps, NextPage } from 'next';

import { SiteBrandguide } from '@components/site/brandguide';
import { SiteProps } from '@typings/site';
import { getIsBrandguideCname } from '@utilities/brandguide';
import { getBrandguideProps } from '@utilities/site';

/**
 * If the Brandguide is CNAME-ed, this page renders a Brandguide pages,
 * like https://brandguide.smartsheet.com/logos.
 *
 * Otherwise, we render the default site with Brandguide slug and page slug,
 * like https://brandguides.brandfolder.com/smartsheet and https://brandguides.brandfolder.com/smartsheet/logos.
 */
const Brandguide: NextPage<SiteProps> = (props) => {
  return <SiteBrandguide {...props} />;
};

export const getServerSideProps: GetServerSideProps<SiteProps> = async (context) => {
  const host = context.req.headers.host || '';

  const isCname = getIsBrandguideCname(
    [process.env.GETTY_BRANDGUIDE_HOST, process.env.NEXTAUTH_URL as string],
    host
  );

  const props = await getBrandguideProps(context, isCname);
  return props;
};

export default Brandguide;
